<template>
  <div
    class="form-login form-reset d-flex justify-content-center align-items-center flex-column"
  >
    <div class="logo img-logo-header">
      <img
        v-if="logoShop"
        :src="logoShop"
        width="100%"
        height="100%"
        style="object-fit: contain; width: 100%; height: 100%"
      />
    </div>
    <div class="w-100 mt-4 mb-3">
      <form>
        <!-- <div class="line-text"><span>パスワードを忘れた方</span></div> -->
        <p v-if="!issuccess" class="message">
          メールアドレスを入力してください。パスワードをリセットするメッセージが届きます。
        </p>
        <div
          style="
            padding: 5px 15px !important;
            border: 1px solid #c3c4c7;
            bor"
        >
          <div v-if="!issuccess" class="d-flex flex-column form-input mt-4">
            <label class="mb-0" style="color: black"
              >ユーザー名またはメールアドレス</label
            >
            <input
              v-model="email"
              type="email"
              name="email"
              id="email"
              placeholder="メールアドレスを入力して下さい。"
              required
            />
          </div>
          <div
            v-if="issuccess"
            class="d-flex justify-content-center align-items-center flex-column h6"
          >
            <span
              >メールはあなたが入力されたメールアドレスに送信されました。</span
            >
            <span>ご確認ください。</span>
          </div>
          <div v-if="!issuccess" class="btn-send">
            <b-button
              v-on:click="sendmail()"
              :disabled="isLoading"
              style="background: black; border: 3px solid black; width: 65%"
            >
              <span> 新しいパスワードを取得 </span>
              <b-spinner v-if="isLoading" small></b-spinner>
            </b-button>
          </div>
          <!-- <div v-if="!issuccess" class="btn-send"> -->
          <div class="btn-send">
            <button
              v-on:click="backLogin()"
              style="
                background: #f1f2f3;
                color: #5e5e64;
                border: 0.5px solid black;
              "
            >
              ログイン画面へ戻る
            </button>
          </div>
        </div>
      </form>
    </div>
    <!-- <div class="text-center">
      <span
        ><a href="\" style="color: black">よくあるご質問</a> &ensp;
        <a href="\" style="color: black">お問い合わせ</a> &ensp;
        <a href="\" style="color: black">会社概要</a> &ensp;
        <a href="\" style="color: black">プライバシーポリシー</a> &ensp;
        <a href="\" style="color: black">利用規約</a> &ensp;
        <a href="\" style="color: black">特定商取引法</a>
        に基づく表記</span
      >
    </div> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";
import no_image from "@/assets/img/logo_default.png";

export default {
  name: "ForgotPasswordAdmin",
  data() {
    return {
      email: "",
      issuccess: false,
      logoShop: null,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      shopId: this.$route.params.shopId,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(["message", "success", "error", "ListSettingShop"]),
  },
  watch: {
    success() {
      if (this.success) {
        this.issuccess = true;
        this.$toasted.success(this.message);
        this.$store.commit("set", ["success", false]);
        this.$store.commit("set", ["message", ""]);
      }
    },
    error() {
      if (this.error) {
        this.issuccess = false;
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    ListSettingShop() {
      this.dataShop = this.ListSettingShop;
      if (this.dataShop.length > 0) {
        if (this.dataShop[0].logo != null) {
          this.logoShop =
            process.env.VUE_APP_ROOT_BACKEND + "/" + this.dataShop[0].logo;
        } else {
          this.logoShop = no_image;
        }
      }
    },
  },
  created() {
    this.getListSettingShopUser({
      domain: Constants.DOMAIN,
      shop_id: this.shopId || null,
    });
    this.$store.commit("set", ["error", false]);
  },
  methods: {
    ...mapActions({
      sendMailForgotPassword: "sendMailForgotPassword",
      getListSettingShopUser: "getListSettingShopUser",
    }),
    async sendmail() {
      this.email = this.email.trim();
      if (this.email == "") {
        this.$toasted.error("メールフィールドは必須です。");
      } else {
        this.isLoading = true;
        let emailData = {
          email: this.email,
          shop_id: this.shop_id,
          type_site: "admin",
        };
        await this.sendMailForgotPassword(emailData);
        this.isLoading = false;
      }
    },
    backLogin() {
      if (window.location.href.includes("admin")) {
        this.$router.push({
          name: this.$route.params.shopId
            ? "login admin"
            : "login admin domain",
          params: { shopId: this.shopId },
        });
      } else {
        this.$router.push({
          name: this.$route.params.shopId ? "login shop" : "login shop domain",
          params: { shopId: this.shopId },
        });
      }
    },
  },
};
</script>
<style scoped>
.img-logo-header {
  width: 286px;
  height: 79px;
}
.message {
  padding: 10px 55px !important;
  margin-bottom: 0 !important;
  background-color: #fff;
  border-left: 4px solid #72aee6;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 10%);
  margin-left: 0;
}
</style>
